<template>
  <index-content>
    <template #options>
      <nb-download :url="CSV_URL" :params="condition" />
      <nb-add @on-click="openModal" />
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th rowspan="2">{{ $t('label.id') }}</th>
            <th rowspan="2">{{ $t('label.name') }}</th>
            <th rowspan="2">{{ $t('label.status') }}</th>
            <th colspan="3">{{ $t('label.restriction') }}</th>
            <th v-table-action rowspan="2" class="action-edit">{{ $t('label.action') }}</th>
          </tr>
          <tr>
            <th>{{ $t('label.login') }}</th>
            <th>{{ $t('label.deposit') }}</th>
            <th>{{ $t('label.withdraw') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td>{{ row.name | hyphen }}</td>
            <td v-enums:GroupStatus="row.status"></td>
            <td><n-icon-boolean :value="row.loginable" /></td>
            <td><n-icon-boolean :value="row.transferDeposit" /></td>
            <td><n-icon-boolean :value="row.transferWithdraw" /></td>
            <td class="n-button-box"><nb-modal @on-click="openModal(row)" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import ModalCompanyGroup from './ModalCompanyGroup';
import { CSV_URL, fetch } from '@/api/company/company-group';

export default BaseCompanyView.extend({
  name: 'CompanyGroup',

  data() {
    return {
      CSV_URL,
      preload: true,
      condition: {
        companyId: 0,
      },
    };
  },

  methods: {
    doLoad() {
      return fetch({
        companyId: this.condition.companyId,
      });
    },

    parse(data) {
      this.records = data;
    },

    openModal(model = {}) {
      this.createModal(ModalCompanyGroup, { model, on: this });
    },
  },
});
</script>
